import { ref, computed } from 'vue'
import store from '@/store'

export default function useEditorNavMenu(props) {
  // ------------------------------------------------
  // isEditorMenuCollapsed
  // ------------------------------------------------
  const isEditorMenuCollapsed = computed({
    get: () => store.state.editorMenu.isEditorMenuCollapsed,
    set: val => {
      store.commit('editorMenu/UPDATE_EDITOR_MENU_COLLAPSED', val)
    },
  })

  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  const collapseTogglerIcon = computed(() => {
    if (props.isEditorMenuActive) {
      return isEditorMenuCollapsed.value ? 'unpinned' : 'pinned'
    }
    return 'close'
  })

  const isMouseHovered = ref(false)

  const updateMouseHovered = val => {
    isMouseHovered.value = val
  }

  const toggleCollapsed = () => {
    isEditorMenuCollapsed.value = !isEditorMenuCollapsed.value
  }

  return {
    isMouseHovered,
    isEditorMenuCollapsed,
    collapseTogglerIcon,
    toggleCollapsed,
    updateMouseHovered,
  }
}
