<template>
  <b-sidebar
    :id="id"
    ref="sidebar"
    :visible="isSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="secondary"
        opacity="0.75"
      >
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <template
            v-if="itemData.id"
          >
            <h5 v-if="blockName">
              {{ $t('Edit') }} {{ blockName }}
            </h5>
            <h5
              v-else
              v-t="`modules.layout_settings.pages.edit_item`"
              class="mb-0"
            />
          </template>
          <template
            v-else
          >

            <h5 v-if="blockName">
              {{ $t('Add') }} {{ blockName }}
            </h5>
            <h5
              v-else
              v-t="`modules.layout_settings.pages.add_item`"
              class="mb-0"
            />
          </template>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Body -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div class="mb-2">
              <b-row>
                <b-col
                  v-for="(setting, settingIndex) in schema"
                  :key="`block-setting-${settingIndex}`"
                  :xl="setting.cols || 12"
                >
                  <div v-if="['header', 'paragraph'].includes(setting.type)">
                    <hr>
                    <p class="font-weight-bolder">
                      {{ setting.label || setting.content }}
                    </p>
                  </div>
                  <i-input
                    v-else
                    :id="setting.id"
                    v-model="itemData.settings[setting.id]"
                    :type="settingTypeToInputType(setting.type)"
                    :title="setting.label"
                    :errors="validationErrors[setting.id]"
                    :options="setting.options"
                    :description="setting.info"
                    :translatable="setting.localizable || false"
                    v-bind="settingBindings(setting)"
                    :translate="false"
                    :required="setting.is_required"
                    :validation-rules="setting.is_required ? ['required'] : []"
                  />
                </b-col>
              </b-row>
            </div>

            <div>
              <i-input
                v-model="itemData.is_active"
                :errors="validationErrors.is_active"
                type="switch"
                title="inputs.is_active"
                description="inputs.is_active:description"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </i-input>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-t="(itemId || itemData.id)?`Edit`:`Add`"
                variant="primary"
                class="mr-2"
                type="submit"
              />
              <b-button
                v-t="`Cancel`"
                variant="outline-secondary"
                @click="hide"
              />
            </div>
          </b-form>
        </validation-observer>

      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BOverlay, BRow, BCol, BCardHeader,
} from 'bootstrap-vue'
import { ref } from 'vue'
import { ValidationObserver } from 'vee-validate'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import formValidation from '@core/comp-functions/forms/form-validation'
import themeBlockItemModel from '@/views/models/settings/layout-settings/themeBlockItemModel'

export default {
  components: {
    BCardHeader,
    BCol,
    BRow,
    ValidationObserver,
    BSidebar,
    BForm,
    BButton,
    BOverlay,
  },
  directives: {
  },
  emits: ['refetch-data', 'section-updated'],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: 'sidebar-add-new-address',
      required: false,
    },
    itemId: {
      type: Number,
      default: null,
    },
    schema: {
      type: Array,
      default: Array,
    },
    templateId: {
      type: Number,
      required: true,
    },
    sectionId: {
      type: [Number, String],
      required: true,
    },
    blockName: {
      type: [String, null],
      default: null,
    },
  },
  watch: {
    itemId(newValue) {
      if (newValue) {
        this.isLoading = true
        this.fetchItem(newValue).finally(() => {
          this.isLoading = false
        })
      } else {
        this.resetForm()
      }
    },
    isSidebarActive(newValue) {
      if (newValue) {
        if (!this.itemId) {
          this.resetForm()
        }
      } else {
        this.resetForm()
      }
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = themeBlockItemModel.name

    // Data definition
    const refs = ref({})
    const emptyItemData = {
      id: null,
      settings: {},
      is_active: true,
    }
    props.schema.forEach(setting => {
      emptyItemData.settings[setting.id] = setting.default
    })
    const itemData = ref({})
    const isLoading = ref(true)

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(emptyItemData))
      isLoading.value = false
      ctx.emit('update:item-id', null)
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const { validationErrors, fetch, save } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      response => {
        ctx.emit('refetch-data')
        ctx.emit('update:is-sidebar-active', false)

        ctx.emit('section-updated', response)
      },
      null,
      null,
      () => {
        // refs.value.value.sidebar.hide()
        itemData.value = JSON.parse(JSON.stringify(emptyItemData))

        ctx.emit('update:is-sidebar-active', false)
      },
    )

    const fetchItem = () => {
      // if (itemData.value.id === props.itemId) return null;
      itemData.value.id = props.itemId
      return fetch()
    }

    const onSubmit = () => {
      isLoading.value = true
      itemData.value.block_template_id = props.templateId
      itemData.value.section_id = props.sectionId
      save(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    const settingTypeToInputType = settingType => {
      switch (settingType) {
        case 'image':
        case 'color':
        case 'select':
          return settingType
        case 'image_picker':
          return 'image'
        case 'checkbox':
          return 'switch'
        case 'radio':
          return 'select'
        default:
          return 'text'
      }
    }

    const settingBindings = setting => {
      switch (setting.type) {
        case 'select':
        case 'radio':
          return {
            reduce: (o => o.value),
          }
        case 'image_picker':
          return setting.meta
        default:
          return {}
      }
    }

    return {
      itemData,
      fetchItem,
      validationErrors,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      refs,
      isLoading,

      settingTypeToInputType,
      settingBindings,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
