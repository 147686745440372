<template>
  <layout-editor>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

    <b-modal
      id="announcement-details"
      ok-variant="primary"
      :ok-title="$t('theme_editor.small_screen_alert_card.btn')"
      :cancel-title="$t('modules.announcements.actions.close')"
      modal-class="modal-warning"
      :title="$t('theme_editor.small_screen_alert_card.title')"
      :visible="isScreenSizeAlertVisible"
      ok-only
      centered
    >
      <p v-t="`theme_editor.small_screen_alert_card.description`" />
    </b-modal>
  </layout-editor>
</template>

<script>
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import LayoutEditor from '@core/layouts/layout-editor/LayoutEditor.vue'
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    LayoutEditor,
    AppCustomizer,
    BModal,
  },
  data() {
    return {
      isScreenSizeAlertVisible: false,
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  mounted() {
    this.isScreenSizeAlertVisible = window.innerWidth < 1024
  },
}
</script>
