<template>
  <b-dropdown
    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
    text="Primary"
    variant="flat-dark"
  >
    <template #button-content>
      <material-icon
        v-if="selectedPage.iconMaterial"
        :icon="selectedPage.iconMaterial"
        size="18"
      />
      <feather-icon
        v-else
        :icon="selectedPage.icon"
        size="18"
      />
      {{ $t(selectedPage.label) }}
    </template>
    <b-dropdown-item
      v-for="option in pageOptions"
      :key="option.id"
      :active="option.id === selectedPage.id"
      @click="$store.dispatch('themeEditor/selectPage', option.id)"
    >
      <material-icon
        v-if="option.iconMaterial"
        :icon="option.iconMaterial"
        size="18"
      />
      <feather-icon
        v-else
        :icon="option.icon"
        size="18"
      />
      {{ $t(option.label) }}
    </b-dropdown-item>
    <!--        <b-dropdown-divider />-->
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { computed } from 'vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  setup(ctx) {
    const pageOptions = [
      {
        id: 'index',
        label: 'theme_editor.pages.home',
        icon: 'HomeIcon',
      },
      {
        id: 'product',
        label: 'theme_editor.pages.product',
        icon: 'BoxIcon',
      },
      {
        id: 'collection',
        label: 'theme_editor.pages.collection',
        iconMaterial: 'shelves',
      },
    ]

    const selectedPage = computed(() => pageOptions.filter(o => o.id === store.state.themeEditor.page)[0] || pageOptions[0])
    const otherPageOptions = computed(() => pageOptions.filter(o => o.id !== store.state.themeEditor.page))
    return {
      pageOptions,
      selectedPage,
      otherPageOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
