<template>
  <div>
    <!-- Settings -->
    <b-overlay
      v-if="settingsLocal.length"
      :show="isLoading"
      variant="light"
      :opacity="0.85"
      rounded="sm"
      class="position-relative"
    >
      <b-card no-body>

        <b-card-header>
          <b-card-title>{{ $t(`modules.layout_settings.pages.settings`) }} <small class="text-muted" /></b-card-title>
        </b-card-header>

        <!-- form -->
        <b-form
          class="mt-2"
          @submit.prevent="submitForm()"
        >
          <div
            v-for="(row, rowIndex) in getSettingsRows(settingsLocal)"
            :key="`${sectionData.name}-${rowIndex}`"
          >
            <b-table-simple
              v-if="row.type === 'header'"
              borderless
              class="mt-2 mb-1"
            >
              <b-thead head-variant="primary">
                <b-tr>
                  <b-th>{{ row.content }}</b-th>
                  <b-th class="text-right" />
                </b-tr>
              </b-thead>
            </b-table-simple>

            <b-row
              v-else
              class="px-2"
            >
              <b-col
                v-for="(setting, settingIndex) in row.items"
                :key="`setting-${rowIndex}-${settingIndex}`"
                :xl="rows ? 12 : (setting.cols || 12)"
              >
                <p v-if="setting.type === 'paragraph'">
                  {{ setting.label }}
                </p>
                <i-input
                  v-else
                  :id="setting.id"
                  v-model="settingsLocal[setting.index].value"
                  :type="settingTypeToInputType(setting.type)"
                  :title="setting.label"
                  :options="setting.options"
                  :description="setting.info"
                  :html-description="setting.info ? marked(setting.info) : null"
                  :translatable="setting.localizable || false"
                  v-bind="settingBindings(setting)"
                  :translate="false"
                />
              </b-col>
            </b-row>

          </div>

        </b-form>
        <!-- form -->

        <b-card-footer class="position-sticky section-settings-footer">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="!hasTouchedAttributes"
            @click="submitForm"
          >
            {{ $t('Save Changes') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            @click.prevent="resetForm"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-card-footer>
      </b-card>
    </b-overlay>
    <!-- Blocks -->
    <b-overlay
      v-if="sectionData.hasOwnProperty('blocks') && sectionData.blocks.length"
      :show="isLoading"
      variant="light"
      :opacity="0.85"
      rounded="sm"
    >
      <b-card no-body>

        <b-card-header>
          <b-card-title>{{ $t(`modules.layout_settings.pages.items`) }}<small class="text-muted" /></b-card-title>
        </b-card-header>

        <!-- form -->
        <div
          class="mt-2"
        >
          <div>
            <!--          <b-card-header>-->
            <!--            <b-card-sub-title>{{ $t(`modules.layout_settings.pages.items`) }} <small class="text-muted" /></b-card-sub-title>-->
            <!--          </b-card-header>-->
            <template
              v-for="(blockTemplate, blockTemplateIndex) in sectionData.blocks"
            >

              <b-table-simple
                :key="`setting-${sectionData.name}-block-template-head-${blockTemplateIndex}`"
                borderless
                class="mt-2 mb-1"
              >
                <b-thead
                  head-variant="info"
                >
                  <b-tr>
                    <b-th>{{ blockTemplate.name }}</b-th>
                    <b-th class="text-right" />
                  </b-tr>
                </b-thead>
              </b-table-simple>
              <section-blocks
                :key="`setting-${sectionData.name}-block-template-content-${blockTemplateIndex}`"
                :template-id="blockTemplate.id"
                :section-id="sectionData.id"
                :schema="blockTemplate.settings"
                :items="blockTemplate.items"
                :block-name="blockTemplate.name"
                :rows="rows"
                @section-updated="section => $emit('section-updated', section)"
                @sorting="sorting => $emit('block-sorting', {sectionId: sectionData.id, data: sorting})"
                @deleted="blockId => $emit('block-deleted', {sectionId: sectionData.id, blockTemplateId: blockTemplate.id, blockId})"
              />
            </template>
          </div>
        </div>
        <!-- form -->
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  BCardTitle, BCardHeader, BTableSimple, BTh, BThead, BTr, BOverlay, BCardText, BCardFooter,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from 'vue'
import SectionBlocks from '@/views/models/settings/layout-settings/SectionBlocks.vue'
import { marked } from 'marked'

export default {
  components: {
    BCardFooter,
    BCardText,
    BTr,
    BThead,
    BTh,
    BTableSimple,
    BCardHeader,
    BCardTitle,
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
    BOverlay,
    SectionBlocks,
  },
  directives: {
    Ripple,
  },
  emits: ['submit', 'section-updated', 'block-sorting', 'block-deleted'],
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      localSectionData: this.sectionData, // Used for changes tracking
      settingsLocal: this.prepareLocalSettings(this.sectionData?.settings),
      profileFile: null,
      isLoading: false,
    }
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      this.settingsLocal.forEach((setting, i) => {
        if (setting.value !== this.sectionData.settings[setting.index].value) {
          attrs[setting.id] = setting.value
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
  },
  watch: {
    sectionData(val) {
      if (JSON.stringify(val) !== JSON.stringify(this.localSectionData)) { // Take action only if data is really changed
        this.resetForm()
      }
    },
  },
  methods: {
    submitForm() {
      this.blockForm()
      this.$emit('submit', {
        data: this.touchedAttributes,
        callback: this.unblockForm,
      })
    },
    resetForm() {
      this.localSectionData = this.sectionData
      this.settingsLocal = this.prepareLocalSettings(this.sectionData?.settings)
    },
    blockForm() {
      this.isLoading = true
    },
    unblockForm() {
      this.isLoading = false
    },
    settingTypeToInputType(settingType) {
      switch (settingType) {
        case 'image':
        case 'color':
        case 'select':
          return settingType
        case 'image_picker':
          return 'image'
        case 'checkbox':
          return 'switch'
        case 'radio':
          return 'select'
        default:
          return 'text'
      }
    },
    settingBindings(setting) {
      switch (setting.type) {
        case 'select':
        case 'radio':
          return {
            reduce: (o => o.value),
          }
        default:
          return {}
      }
    },
    prepareLocalSettings(settings) {
      const localSettings = settings ? JSON.parse(JSON.stringify(settings)) : {}
      Object.keys(localSettings).forEach(i => {
        // const setting = localSettings[i]
        // if (setting.type === 'checkbox') {
        //   localSettings[i].value = setting.value
        // }
        localSettings[i].index = i
      })
      return localSettings
    },
    getSettingsRows(settings) {
      const rows = []
      let currentRowSettings = []
      Object.values(settings).forEach(setting => {
        if (setting.type === 'header') {
          if (currentRowSettings.length) {
            rows.push({
              type: 'row',
              items: currentRowSettings,
            })
            currentRowSettings = []
          }
          rows.push(setting)
        } else {
          currentRowSettings.push(setting)
        }
      })
      if (currentRowSettings.length) {
        rows.push({
          type: 'row',
          items: currentRowSettings,
        })
      }
      return rows
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    //
    // watch([props.settings], () => {
    //   //this.resetForm()
    //   console.log('updated 2')
    // })

    // Override function
    // const renderer = {
    //   link(href, title, text) {
    //     return `<a href="${href}" target="_blank">${text}</a>`
    //   },
    // }
    const renderer = marked.Renderer
    renderer.link = (href, title, text) => `<a href="${href}" target="_blank">${text}</a>`

    marked.use({ renderer })

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,

      marked,
    }
  },
}
</script>

<style lang="css">
.content-body .text-muted {
  color: unset !important;
}
.section-settings-footer {
  bottom: 22px;
  z-index: 99;
  background-color: rgb(243, 242, 247) !important;
}
</style>
