<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isEditorMenuCollapsed || (isEditorMenuCollapsed && isMouseHovered) },
      finalSkin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded mb-2">
      <slot
        name="header"
        :toggleEditorMenuActive="toggleEditorMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <div
              v-if="$store.state.themeEditor.activeSection && ($store.getters['themeEditor/allSections'].length)"
              class="navbar-brand"
            >
              <b-link
                v-b-tooltip.hover.bottom
                :title="$t('Back')"
                @click="$store.dispatch('themeEditor/clearSectionSelection')"
              >
                <span class="brand-logo">
                  <feather-icon
                    :icon="$i18n.locale === 'ar' ? 'ArrowRightIcon' : 'ArrowLeftIcon'"
                  />
                </span>
              </b-link>
              <h2
                class="brand-text mr-50 text-truncate"
              >
                {{ $store.state.themeEditor.activeSection.name }}
              </h2>
            </div>
            <b-link
              v-else
              class="navbar-brand"
              href="/layout-settings/"
            >
              <span class="brand-logo">
                <feather-icon
                  v-b-tooltip.hover.bottom
                  :title="$t('Exit')"
                  icon="LogOutIcon"
                  :class="{
                    'rotate-180': $i18n.locale === 'en'
                  }"
                />
              </span>
              <h2
                class="brand-text mr-50 text-truncate"
              >
                {{ $t('Exit') }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleEditorMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <b-overlay
      :show="isLoadingSection || isLoadingSectionsList"
      rounded="sm"
      variant="secondary"
      opacity="0.75"
      class="h-100"
    >
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
      >
        <LayoutSection
          v-if="$store.state.themeEditor.activeSection"
          :section-data="{
            id: $store.state.themeEditor.activeSectionId,
            name: $store.state.themeEditor.activeSection.name,
            settings: $store.state.themeEditor.sectionSettings,
            blocks: $store.state.themeEditor.sectionBlocks
          }"
          :rows="true"
          class="static-width-content"
          @submit="payload => $store.dispatch('themeEditor/saveSectionSettings', payload)"
          @block-sorting="payload => $store.dispatch('themeEditor/saveSectionBlocksSorting', payload)"
          @section-updated="section => $store.dispatch('themeEditor/loadSectionUpdates')"
          @block-deleted="payload => $store.dispatch('themeEditor/loadSectionUpdates')"
        />

        <div
          v-else
          class="navigation navigation-main"
        >
          <editor-nav-menu-items
            v-if="fixedTopNavMenuItems.length"
            :items="fixedTopNavMenuItems"
            :allow-addition="false"
            @click="onClickSectionTitle"
          />
          <editor-nav-menu-items
            v-if="navMenuItems.length"
            :items="navMenuItems"
            :allow-addition="true"
            @click="onClickSectionTitle"
          />
          <editor-nav-menu-items
            v-if="fixedBottomNavMenuItems.length"
            :items="fixedBottomNavMenuItems"
            :allow-addition="false"
            @click="onClickSectionTitle"
          />
        </div>
      </vue-perfect-scrollbar>
    </b-overlay>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import {
  provide, computed, ref, onBeforeMount,
} from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { shop } from '@core/utils/utils'
import store from '@/store'
import LayoutSection from '@/views/models/settings/layout-settings/LayoutSection.vue'
import EditorNavMenuItems from './components/editor-nav-menu-items/EditorNavMenuItemsV2.vue'
import useEditorNavMenu from './useEditorNavMenu'

export default {
  components: {
    LayoutSection,
    BOverlay,
    VuePerfectScrollbar,
    EditorNavMenuItems,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditorMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleEditorMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props, ctx) {
    const {
      isMouseHovered,
      isEditorMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useEditorNavMenu(props)

    const { skin } = useAppConfig()

    const finalSkin = computed(() => (store.state.themeEditor.activeSectionId ? 'light' : skin))

    // Shadow bottom is UI specific and can be removed by user => It's not in `useEditorNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, versionName, appLogoImage } = $themeConfig.app
    const myStore = shop()

    const mapSectionToIcon = sectionCode => {
      switch (sectionCode) {
        case 'header':
          return 'splitscreen_top'
        case 'footer':
          return 'splitscreen_bottom'
        default:
          return 'crop_16_9'
      }
    }
    const fixedTopNavMenuItems = computed(() => {
      const items = []
      const { settingSections } = store.state.themeEditor
      if (settingSections.length) {
        items.push({ header: 'theme_editor.headers.general_settings' })
        settingSections.map(section => ({
          id: section.id,
          code: section.slug,
          title: section.name,
          iconMaterial: 'settings',
          tag: '',
          tagVariant: 'light-primary',
          isActive: true,
          isEditable: false,
        })).forEach(item => items.push(item))
      }

      if (store.state.themeEditor.preSections.length || store.state.themeEditor.sortableSections.length || store.state.themeEditor.postSections.length) {
        items.push({ header: 'theme_editor.headers.page_sections' })

        store.state.themeEditor.preSections.map(section => ({
          id: section.id,
          code: section.slug,
          title: section.name,
          iconMaterial: (section.is_active ?? true) ? mapSectionToIcon(section.slug) : 'visibility_off',
          tag: section.is_new ? 'New' : '',
          tagVariant: 'light-primary',
          isActive: (section.is_active ?? true),
          isEditable: false,
        })).forEach(item => items.push(item))
      }

      return items
    })

    const fixedBottomNavMenuItems = computed(() => store.state.themeEditor.postSections.map(section => ({
      id: section.id,
      code: section.slug,
      title: section.name,
      iconMaterial: (section.is_active ?? true) ? mapSectionToIcon(section.slug) : 'visibility_off',
      tag: section.is_new ? 'New' : '',
      tagVariant: 'light-primary',
      isActive: (section.is_active ?? true),
      isEditable: true,
    })))

    const navMenuItems = computed(() => store.state.themeEditor.sortableSections.map(section => ({
      id: section.id,
      code: section.slug,
      title: section.name,
      iconMaterial: (section.is_active ?? true) ? mapSectionToIcon(section.slug) : 'visibility_off',
      tag: section.is_new ? 'New' : '',
      tagVariant: 'light-primary',
      isActive: (section.is_active ?? true),
      isEditable: true,
    })))

    const isLoadingSection = ref(false)
    const isLoadingSectionsList = ref(false)
    const loadSectionsList = () => {
      isLoadingSectionsList.value = true
      store.dispatch('themeEditor/loadSections', { theme: 'start', page: 'index' }).finally(() => {
        isLoadingSectionsList.value = false
      })
    }
    onBeforeMount(() => {
      loadSectionsList()
    })
    const onClickSectionTitle = item => {
      isLoadingSection.value = true
      store.dispatch('themeEditor/loadSection', item.id).finally(() => {
        isLoadingSection.value = false
      })
    }

    return {
      fixedTopNavMenuItems,
      fixedBottomNavMenuItems,
      navMenuItems,
      perfectScrollbarSettings,
      isEditorMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      onClickSectionTitle,
      isLoadingSection,
      isLoadingSectionsList,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      finalSkin,

      // App Name
      myStore,
      appName,
      versionName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/editor-menu.scss";

</style>
