import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import themeBlockItemModel from './themeBlockItemModel'

const {
  // fetchAll,
  fetchOne,
  create,
  update,
  deleteMany,
  deleteOne,
  sorting,
} = useModelStoreModule(themeBlockItemModel)

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetchAll,
    fetchOne,
    create,
    update,
    deleteMany,
    deleteOne,
    sorting,
  },
}
