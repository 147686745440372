<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="SettingsIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <preview-mode />
    </div>

    <div class="align-items-center mx-auto flex-grow-0 flex-lg-grow-1">
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <editor-page-selector />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import { isDevelopment } from '@core/utils/utils'
import PreviewMode from '@core/layouts/components/app-navbar/components/PreviewMode.vue'
import Ripple from 'vue-ripple-directive'
import EditorPageSelector from '@core/layouts/components/app-navbar/components/EditorPageSelector.vue'

export default {
  components: {
    EditorPageSelector,
    PreviewMode,
    BLink,

    // Navbar Components
    BNavbarNav,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: { isDevelopment },
  setup(ctx) {
    return {
    }
  },
}
</script>

<style>
.navbar-brand .brand-text {
  width: 300px;
}
</style>
