<template>
  <b-overlay
    :show="isLoading"
    variant="light"
    :opacity="0.85"
    rounded="sm"
  >
    <b-card
      :img-src="item.image"
      img-top
      border-variant="secondary"
      bg-variant="transparent"
      header=""
      :title="item.title"
      class="block-item text-center mb-1"
      header-class=""
      footer-class="text-muted"
    >
      <b-card-text v-if="item.subtitle">
        {{ item.subtitle }}
      </b-card-text>
      <template slot="footer">
        <a>
          <feather-icon
            icon="MoveIcon"
            class="ml-1 move-handle cursor-move"
          />
        </a>
        <a>
          <feather-icon
            icon="EditIcon"
            class="ml-1"
            @click="$emit('edit')"
          />
        </a>
        <a>
          <feather-icon
            icon="TrashIcon"
            class="ml-1"
            @click="onDeleteClick"
          />
        </a>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardText, BOverlay,
} from 'bootstrap-vue'
import {
  ref,
} from 'vue'
import useModelDelete, {
  DEFAULT_ALWAYS_CALLBACK,
  DEFAULT_ON_APPROVE_CALLBACK, DEFAULT_ON_FAILURE_CALLBACK,
  DEFAULT_ON_SUCCESS_CALLBACK,
} from '@/views/models/common/useModelDelete'
/* eslint-disable global-require */
export default {
  components: {
    BOverlay,
    BCardText,
    BCard,
  },
  directives: {
  },
  emits: ['edit', 'deleted'],
  props: {
    item: {
      type: Object,
      default: () => ({
        title: null,
        subtitle: null,
        image: null,
      }),
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },

  setup(props, ctx) {
    const SECTION_BLOCK_STORE_MODULE_NAME = 'theme_section_blocks'

    const isLoading = ref(false)

    const {
      deleteItem,
    } = useModelDelete(
      SECTION_BLOCK_STORE_MODULE_NAME,
      false,
      () => {
        DEFAULT_ON_APPROVE_CALLBACK()
        isLoading.value = true
      },
      () => {
        DEFAULT_ON_SUCCESS_CALLBACK()
        ctx.emit('deleted')
      },
      () => {
        DEFAULT_ON_FAILURE_CALLBACK()
      },
      () => {
        DEFAULT_ALWAYS_CALLBACK()
        isLoading.value = false
      },
    )
    const onDeleteClick = () => {
      deleteItem(props.item.block_id)
    }

    return {
      isLoading,
      onDeleteClick,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="css">
[dir] .block-item .card-title {
  margin-bottom: 1rem;
}
</style>
