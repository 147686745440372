var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    { 'expanded': !_vm.isEditorMenuCollapsed || (_vm.isEditorMenuCollapsed && _vm.isMouseHovered) },
    _vm.finalSkin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded mb-2"},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[(_vm.$store.state.themeEditor.activeSection && (_vm.$store.getters['themeEditor/allSections'].length))?_c('div',{staticClass:"navbar-brand"},[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],attrs:{"title":_vm.$t('Back')},on:{"click":function($event){return _vm.$store.dispatch('themeEditor/clearSectionSelection')}}},[_c('span',{staticClass:"brand-logo"},[_c('feather-icon',{attrs:{"icon":_vm.$i18n.locale === 'ar' ? 'ArrowRightIcon' : 'ArrowLeftIcon'}})],1)]),_c('h2',{staticClass:"brand-text mr-50 text-truncate"},[_vm._v(" "+_vm._s(_vm.$store.state.themeEditor.activeSection.name)+" ")])],1):_c('b-link',{staticClass:"navbar-brand",attrs:{"href":"/layout-settings/"}},[_c('span',{staticClass:"brand-logo"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],class:{
                  'rotate-180': _vm.$i18n.locale === 'en'
                },attrs:{"title":_vm.$t('Exit'),"icon":"LogOutIcon"}})],1),_c('h2',{staticClass:"brand-text mr-50 text-truncate"},[_vm._v(" "+_vm._s(_vm.$t('Exit'))+" ")])])],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleEditorMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleCollapsed}})],1)],1)])]},{"toggleEditorMenuActive":_vm.toggleEditorMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{'d-block': _vm.shallShadowBottom}}),_c('b-overlay',{staticClass:"h-100",attrs:{"show":_vm.isLoadingSection || _vm.isLoadingSectionsList,"rounded":"sm","variant":"secondary","opacity":"0.75"}},[_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) { _vm.shallShadowBottom = evt.srcElement.scrollTop > 0 }}},[(_vm.$store.state.themeEditor.activeSection)?_c('LayoutSection',{staticClass:"static-width-content",attrs:{"section-data":{
          id: _vm.$store.state.themeEditor.activeSectionId,
          name: _vm.$store.state.themeEditor.activeSection.name,
          settings: _vm.$store.state.themeEditor.sectionSettings,
          blocks: _vm.$store.state.themeEditor.sectionBlocks
        },"rows":true},on:{"submit":function (payload) { return _vm.$store.dispatch('themeEditor/saveSectionSettings', payload); },"block-sorting":function (payload) { return _vm.$store.dispatch('themeEditor/saveSectionBlocksSorting', payload); },"section-updated":function (section) { return _vm.$store.dispatch('themeEditor/loadSectionUpdates'); },"block-deleted":function (payload) { return _vm.$store.dispatch('themeEditor/loadSectionUpdates'); }}}):_c('div',{staticClass:"navigation navigation-main"},[(_vm.fixedTopNavMenuItems.length)?_c('editor-nav-menu-items',{attrs:{"items":_vm.fixedTopNavMenuItems,"allow-addition":false},on:{"click":_vm.onClickSectionTitle}}):_vm._e(),(_vm.navMenuItems.length)?_c('editor-nav-menu-items',{attrs:{"items":_vm.navMenuItems,"allow-addition":true},on:{"click":_vm.onClickSectionTitle}}):_vm._e(),(_vm.fixedBottomNavMenuItems.length)?_c('editor-nav-menu-items',{attrs:{"items":_vm.fixedBottomNavMenuItems,"allow-addition":false},on:{"click":_vm.onClickSectionTitle}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }