<template>
  <div
    class="py-50 position-relative"
    :class="{
      'divider-hovered': isHover
    }"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <hr>
    <b-button
      v-show="isHover"
      variant="gradient-success"
      class="btn-icon rounded-circle add-section-btn"
      @click="$emit('clickAddSection')"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>
  </div>
</template>

<script>

import { ref } from 'vue'
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  emits: ['clickAddSection'],
  props: {
  },
  setup() {
    const isHover = ref(false)
    return {
      isHover,
    }
  },
}
</script>

<style lang="css">
.add-section-btn {
  position: absolute;
  top: 5px;
  right: 224px;
  right: calc(50% - 32px/2);
}
.divider-hovered hr {
  border-top: 4px solid #49cd64;
}
</style>
