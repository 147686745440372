<template>
  <div
    class="editor-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleEditorMenuActive="toggleEditorMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-editor-layout :toggle-vertical-menu-active="toggleEditorMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Editor Nav Menu -->
    <editor-nav-menu
      v-if="!isNavMenuHidden"
      :is-editor-menu-active="isEditorMenuActive"
      :toggle-editor-menu-active="toggleEditorMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="editor-menu-header"
          v-bind="slotProps"
        />
      </template>
    </editor-nav-menu>
    <!-- /Editor Nav Menu -->

    <!-- Editor Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isEditorMenuActive = false"
    />
    <!-- /Editor Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <LayoutExternalContentRendererLeft
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </LayoutExternalContentRendererLeft>
    </transition>
    <!--/ Content -->
  </div>
</template>

<script>
import { onUnmounted } from 'vue'
import AppNavbarEditorLayout from '@core/layouts/components/app-navbar/AppNavbarEditorLayout.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutExternalContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutExternalContentRendererLeft.vue'
import EditorNavMenu from './components/editor-nav-menu/EditorNavMenu.vue'
import useEditorLayout from './useEditorLayout'
import mixinEditorLayout from './mixinEditorLayout'

export default {
  components: {
    LayoutExternalContentRendererLeft,
    // AppBreadcrumb,
    AppNavbarEditorLayout,
    EditorNavMenu,
    BNavbar,
  },
  mixins: [mixinEditorLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isEditorMenuActive,
      toggleEditorMenuActive,
      isEditorMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      // navbarTypeClass,
      footerTypeClass,
    } = useEditorLayout(navbarType, 'hidden')

    const navbarTypeClass = 'fixed-top'

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isEditorMenuActive,
      toggleEditorMenuActive,
      isEditorMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
@import "~@core/scss/base/core/mixins/main-menu-mixin";

//.header-navbar {
//  &.floating-nav {
//    width: calc(100vw - (100vw - 100%) - calc(#{$content-padding} * 2) - #{$editor-menu-expanded-width});
//  }
//}
//.main-menu {
//  .navbar-header {
//    width: $editor-menu-expanded-width;
//  }
//}
.content {
  margin-left: $editor-menu-expanded-width;
}
html[dir="rtl"] .editor-menu-modern .content.app-content {
  margin-right: $editor-menu-expanded-width;
  margin-left: unset;
}

.content-area-wrapper,
.kanban-wrapper {
  @include content-area-wrapper-height(0rem, #{$navbar-height}, 0rem, 0rem, 0.3rem, 2);
}

html[dir] .content.app-content {
  padding: 4.75rem 0 0 0;
}
</style>
